.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.ticketform {
    align-items: center;
    border-radius: 1rem;
    box-shadow: 1px 1px 3px gray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1rem;
    padding: 1rem 2rem;
    width: min-content;
}

.maindiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1rem 0.1rem;
    
    & input {
        padding: 0.5rem;
        border-radius: 0.5rem;
        border: 1px solid gray;
    }

    & input:focus {
        border: 1px solid gray;
    }

}


textarea {
    display: block;
    height: 5rem;
    overflow-y: scroll;
    width: 20rem;
    resize: none;
}

.ticketform input[type=submit] {
    padding: 0.5rem 1rem;
    background-color: green;
    border: none;
    color: white;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: .3s ease;

    &:hover {
        background-color: darken($color: green, $amount: 10);
        transition: .3s ease;
    }
}