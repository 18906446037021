*, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: Arial, Helvetica, sans-serif;
}

@import './home/home.scss';
@import './tickets/ticket.scss';